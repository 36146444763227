<template>
  <div>
    <div class="content-general-information">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p  class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <div>
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
        </v-row>
        <v-divider class="divider-global mt-9"></v-divider>

        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-skeleton-loader width="auto" type="button"></v-skeleton-loader>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-skeleton-loader width="auto" type="button"></v-skeleton-loader>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.content-general-information {
  display: flex;
  width: 100%;
}
.content-btns-accions {
  display: flex;
  width: 100%;
  margin-top: 25px;
  justify-content: flex-end;
  place-self: end;
}
.content-btn-modify {
  width: 200px;
  margin-left: 15px;
}
.content-btn-update {
  width: 200px;
  margin-left: 15px;
}
.content-btn-delete {
  width: 200px;
}
.content-btn-cancel {
  width: 200px;
}

.p-text-title {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}
.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}
.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}

.content-information {
  align-items: center;
  display: flex;
}

.p-title {
  color: var(--primary-color-text);
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 14px;
}
.p-text {
  color: var(--primary-color-text);
  margin-bottom: 0px;
  margin-left: 10px;
  font-family: "pop-Regular";
  font-size: 14px;
}
.text-color {
  /* -webkit-text-stroke: .5px black; */
  color: var(--primary-color-text) !important;
  margin-bottom: 0px;
  width: 50%;
  border: solid var(--primary-color-text) 1px !important;
  /* margin-left: 10px; */
  font-family: "pop-Regular" !important;
  font-size: 14px !important;
}
.content-img {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  height: 100px;
  width: 100px;
}
.content-img img {
  width: 100%;
  height: auto;
}
@supports (object-fit: cover) {
  .content-img img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 20px;
  }
}
.content-btn {
  width: 170px;
  align-self: center;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-general-information {
    display: block;
    width: 100%;
  }
  .centered-image {
    display: flex;
    justify-content: center !important;
    text-align-last: center !important;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .content-information {
    margin-bottom: 10px;
  }
  .p-text-title {
    text-align: center;
  }
  .p-title {
    text-align: center;
  }

  .p-text {
    text-align: center;
    margin-left: 0px;
  }
  .content-color {
    text-align: -webkit-center;
  }
  .content-btn {
    width: 100%;
  }
  .content-btns-accions {
    display: block;
    width: 100%;
    margin-top: 25px;
    justify-content: flex-end;
    place-self: end;
  }
  .content-btn-modify {
    width: 100%;
    margin-left: 0px;
    margin-top: 15px;
  }
  .content-btn-update {
    width: 100%;
    margin-left: 0px;
    margin-top: 15px;
  }
  .content-btn-delete {
    width: 100%;
    margin-top: 15px;
  }
  .content-btn-cancel {
    width: 100%;
    margin-top: 15px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
  .content-btns-accions {
    display: flex;
    width: 100%;
    margin-top: 25px;
    justify-content: flex-end;
    place-self: end;
  }
  .content-btn-modify {
    width: 140px;
    margin-left: 15px;
  }
  .content-btn-update {
    width: 140px;
    margin-left: 15px;
  }
  .content-btn-delete {
    width: 140px;
  }
  .content-btn-cancel {
    width: 140px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>